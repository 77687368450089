<script>
import Layout from "@/router/layouts/main";
import appConfig from "@/app.config";
import PageHeader from "@/components/page-header";
import axios from "axios";
import Swal from "sweetalert2";
// import moment from 'moment';
import Cookies from "js-cookie";

/**
 * Dashboard Component
 */
export default {
  page: {
    title: "Surat Keluar",
    meta: [
      {
        name: "description",
        content: appConfig.description,
      },
    ],
  },
  components: {
    Layout,
    PageHeader,
  },
  data() {
    return {
      title: "Surat Keluar",
      items: [
        {
          text: "Home",
          href: "/",
        },
        {
          text: "Surat Keluar",
          active: true,
        },
      ],
      // variable Page Table
      loadingTable: true,
      table_data: [],
      search: "",

      // data filter
      departement: "",
      sifat: "",
      bentuk: "",
      kategori: [],
      kategori_selected: "",
      master_user: [],
      master_user_selected: [],
      duedate: "",
      role_slug: Cookies.get("session_role_slug"),
      showAkses: false,
    };
  },
  mounted() {
    this.getDataTable();
    this.getKategori();
    this.getAkses();
    this.getSelectedAkses();
  },
  methods: {
    getDataTable() {
      let self = this;
      self.loadingTable = true;

      var kategori_id = self.kategori_selected?.id;
      if (kategori_id) {
        kategori_id = self.kategori_selected?.id;
      } else {
        kategori_id = '';
      }

      axios
        .get(
          process.env.VUE_APP_BACKEND_URL_VERSION +
          "/api/arsip-dokumen/suratkeluar?role_slug=" +
          self.role_slug +
          "&departement=" +
          Cookies.get("session_departemen") +
          "&id_user=" +
          Cookies.get("session_id") +
          "&sifat=" +
          self.sifat +
          "&bentuk=" +
          self.bentuk +
          "&kategori=" +
          kategori_id +
          "&duedate=" +
          self.duedate +
          "&search=" +
          self.search
        )
        .then((response) => {
          var response_data = response.data;
          if (response_data.code == 200) {
            self.table_data = response_data.list_data;
            //console.log(self.table_data);
            self.loadingTable = false;
          } else {
            Swal.fire({
              icon: "error",
              title: "Oops...",
              text: response_data.message,
            });
          }
        });
    },
    getKategori() {
      let self = this;
      axios
        .get(
          process.env.VUE_APP_BACKEND_URL_VERSION +
          "/api/master/departemen?status=ENABLE"
        )
        .then((response) => {
          var response_data = response.data;
          if (response_data.code == 200) {
            self.kategori = response_data.list_data.data;
          } else {
            Swal.fire({
              icon: "error",
              title: "Oops...",
              text: response_data.message,
            });
          }
        });
    },
    getAkses() {
      let self = this;
      self.loadingTable = true;
      axios
        .get(
          process.env.VUE_APP_BACKEND_URL_VERSION +
          "/api/arsip-dokumen/suratkeluar/suratkeluarakses"
        )
        .then((response) => {
          var response_data = response.data;
          if (response_data.code == 200) {
            self.master_user_selected = response_data.list_data;
          } else {
            Swal.fire({
              icon: "error",
              title: "Oops...",
              text: response_data.message,
            });
          }
        });
    },
    getSelectedAkses() {
      let self = this;
      self.loadingTable = true;
      axios
        .get(
          process.env.VUE_APP_BACKEND_URL_VERSION +
          "/api/master/user/aksessurat?role_slug=" + Cookies.get("session_role_slug")
        )
        .then((response) => {
          var response_data = response.data;
          if (response_data.code == 200) {
            self.master_user = response_data.list_data.data;
          } else {
            Swal.fire({
              icon: "error",
              title: "Oops...",
              text: response_data.message,
            });
          }
        });
    },
    //filter data
    filterData() {
      this.getDataTable();
    },
    eksporHandle() {
      let self = this;
      var kategori_id = self.kategori_selected?.id;
      if (kategori_id) {
        kategori_id = self.kategori_selected?.id;
      } else {
        kategori_id = '';
      }
      const link = document.createElement("a");
      link.href = process.env.VUE_APP_BACKEND_URL + "/api/arsip-dokumen/suratkeluar/excel?status=&tipe=&sifat=" +
        self.sifat +
        "&bentuk=" +
        self.bentuk +
        "&tanggal_surat=" +
        self.tanggal_surat + "&kategori="+kategori_id+"&duedate="+self.duedate+"&role_slug=" +
        self.role_slug +
        "&departement=" +
        Cookies.get("session_departemen");
      link.setAttribute('target', '_blank');
      link.click();
    },

    StoreDataAkses() {
      let self = this;
      Swal.fire({
        title: '<i class="fas fa-spinner fa-spin"></i>',
        text: "Loading...",
        showConfirmButton: false,
      });
      var FormData = require("form-data");
      var data = new FormData();
      data.append("akses", JSON.stringify(self.master_user_selected));

      var config = {
        method: "post",
        url:
          process.env.VUE_APP_BACKEND_URL_VERSION +
          "api/arsip-dokumen/suratkeluar/editakses",
        data: data,
      };
      axios(config)
        .then(function (response) {
          var response_data = response.data;
          if (response_data.code != 200) {
            Swal.fire({
              icon: "error",
              title: "Oops...",
              html: response_data.message,
            });
          } else {
            let timerInterval;
            Swal.fire({
              icon: "success",
              title: "Berhasil",
              text: "Anda akan diarahkan ke halaman arsip dokumen surat keluar segera",
              timer: 2000,
              timerProgressBar: true,
              showCancelButton: false,
              showConfirmButton: false,
              willClose: () => {
                clearInterval(timerInterval);
              },
            }).then((result) => {
              /* Read more about handling dismissals below */
              if (result.dismiss === Swal.DismissReason.timer) {
                self.getDataTable();
                self.showAkses = false;
              }
            });
          }
        })
        .catch((e) => {
          this.axiosCatchError = e.response.data.data;
          Swal.close();
        });
    },
    hapusData(id) {
      var alert_text = "Apakah Anda yakin?";
      Swal.fire({
        title: "Warning ?",
        text: alert_text,
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Ya",
        cancelButtonText: "Tidak",
      }).then((result) => {
        if (result.isConfirmed) {
          let self = this;
          Swal.fire({
            title: '<i class="fas fa-spinner fa-spin"></i>',
            text: "Loading...",
            showConfirmButton: false,
          });
          var FormData = require("form-data");
          var data = new FormData();
          data.append("id", id);

          var config = {
            method: "post",
            url:
              process.env.VUE_APP_BACKEND_URL_VERSION +
              "/api/arsip-dokumen/suratkeluar/delete",
            data: data,
          };
          axios(config)
            .then(function (response) {
              console.log(response);
              let timerInterval;
              Swal.fire({
                icon: "success",
                title: "Berhasil",
                text: "Anda akan diarahkan ke halaman arsip dokumen surat keluar segera",
                timer: 2000,
                timerProgressBar: true,
                showCancelButton: false,
                showConfirmButton: false,
                willClose: () => {
                  clearInterval(timerInterval);
                },
              }).then((result) => {
                /* Read more about handling dismissals below */
                if (result.dismiss === Swal.DismissReason.timer) {
                  Swal.close();
                  self.getDataTable();
                }
              });
            })
            .catch((e) => {
              this.axiosCatchError = e.response.data.data;
              Swal.close();
            });
        } else {
          Swal.close();
        }
      });
    },
  },
};
</script>
<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <div class="row mb-2">
      <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 text-center">
        <ul class="list-group list-group-horizontal">
          <li class="col-md-6 list-group-item fs-5 active">
            <router-link :to="{ name: 'all-surat_keluar' }" class="text-light">
              <i class="bx bx-mail-send"> </i> Surat Keluar
            </router-link>
          </li>
          <li class="col-md-6 list-group-item fs-5">
            <router-link :to="{ name: 'surat_keluar_booking' }" class="text-dark">
              <i class="bx bx-list-ol fs-5"> </i> Booking Slot
              <span class="badge bg-primary rounded-pill">{{ total_draft }}</span>
            </router-link>
          </li>
        </ul>
      </div>
    </div>
    <div class="row">
      <div class="col-lg-12">
        <div class="card">
          <div class="card-body">
            <div class="row">
              <div class="col-md-2">
                <label for="">Sifat Surat</label>
                <select class="form-control input-sm" v-model="sifat">
                  <option value="">Semua</option>
                  <option value="biasa">Biasa</option>
                  <option value="rahasia">Rahasia</option>
                  <option value="sangat_rahasia">
                    Sangat Rahasia
                  </option>
                </select>
              </div>
              <div class="col-md-2">
                <label for="">Bentuk Surat</label>
                <select class="form-control input-sm" v-model="bentuk">
                  <option value="">Semua</option>
                  <option value="surat">Surat</option>
                  <option value="surat_dan_proposal">
                    Surat dan Proposal
                  </option>
                  <option value="surat_dan_dokumen_pendukung_lainnya">
                    Surat dan Pendukung Lainnya
                  </option>
                </select>
              </div>
              <div class="col-md-2">
                <label for="">Kategori Surat</label>
                <select class="form-control input-sm" v-model="kategori_selected">
                  <option value="">Semua</option>
                  <option v-for="item in kategori" :value="item" :key="item.id">{{ item.nama }}</option>
                </select>
              </div>
              <!-- <div class="col-md-2">
                <label for="">Due Date</label>
                <input type="date" class="form-control" v-model="duedate" />
              </div> -->
              <div class="col-md-2">
                <label for="">&nbsp;</label><br>
                <button class="btn btn-primary rounded-0" @click="filterData" type="button">
                  <i class="fa fa-search"></i> Filter
                </button>
              </div>
            </div>
            <div class="row" v-if="role_slug != 'officer' && role_slug != 'kepala_divisi'">
              <div class="col-md-12">
                <div class="text-end">
                  <button v-if="role_slug != 'team_leader'" type="button" class="btn btn-warning m-1 rounded-0"
                    @click="showAkses = true">
                    <i class="fa fa-user"></i>
                    Akses
                  </button>
                  <button v-if="role_slug != 'team_leader'" @click="eksporHandle" class="btn btn-success m-1 rounded-0"><i
                      class="fas fa-file-excel"></i>
                    Download Excel</button>
                  <router-link :to="{ name: 'add-surat_keluar', params: { tipe: 'surat_keluar' } }" class="btn btn-info m-1 rounded-0"><i
                      class="fa fa-plus"></i>
                    Tambah Surat Keluar</router-link>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-md-9"></div>
              <div class="col-md-3 mb-2">
                <input type="text" class="form-control" id="searchTable" v-model="search" @input="getDataTable()"
                  placeholder="Cari Data ..." />
              </div>
            </div>
            <div class="col-md-12">
              <div class="table-responsive">
                <table class="table mb-0 table-bordered table-condensed table-hover table-striped">
                  <thead>
                    <tr>
                      <th class="text-center text-white" style="background-color: #132d4a">No</th>
                      <th class="text-center text-white" style="background-color: #132d4a">Nomor Surat</th>
                      <th  class="text-center text-white" style="background-color: #132d4a;width: 150px;">Tanggal Surat</th>
                      <th class="text-center text-white" style="background-color: #132d4a">Instansi Penerima</th>
                      <th class="text-center text-white" style="background-color: #132d4a">Sifat Surat</th>
                      <th class="text-center text-white" style="background-color: #132d4a">Bentuk Surat</th>
                      <th class="text-center text-white" style="background-color: #132d4a">Kategori Surat</th>
                      <th class="text-center text-white" style="background-color: #132d4a">Perihal</th>
                      <th  class="text-center text-white" style="background-color: #132d4a;width: 200px;">Aksi</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-if="loadingTable">
                      <td colspan="9">
                        <i class="fas fa-spinner fa-spin"></i> Loading...
                      </td>
                    </tr>
                    <tr v-else-if="table_data == ''">
                      <td class="text-center" colspan="9">
                        Data Tidak Tersedia
                      </td>
                    </tr>
                    <tr v-else v-for="(row_data, key_data) in table_data" :key="key_data">
                      <td class="text-center">
                        {{ key_data + 1 }}
                      </td>
                      <td>
                        {{ row_data.ad_nomorsurat }}
                      </td>
                      <td class="text-center">
                        {{ row_data.ad_tanggalsurat }}
                      </td>
                      <td>
                        {{ row_data.ad_instansipengirim }}
                      </td>
                      <td>
                        {{ row_data.ad_sifatsurat }}
                      </td>
                      <td>
                        {{ row_data.ad_bentukdokumen }}
                      </td>
                      <td>
                        {{ row_data.ad_kategorisurat }}
                      </td>
                      <td>
                        {{ row_data.ad_perihal }}
                      </td>
                      <td class="text-center">
                        <div class="btn-group">
                          <router-link :to="{
                            name: 'edit-surat_keluar',
                            params: { tipe: 'surat_keluar',id: row_data.ad_id },
                          }" class="btn btn-sm btn-info"><i class="fa fa-edit"></i> Edit</router-link>
                          <router-link :to="{
                            name: 'detail-surat_keluar',
                            params: { id: row_data.ad_id },
                          }" class="btn btn-sm btn-primary"><i class="fa fa-eye"></i> Detail</router-link>
                          <button type="button" v-if="role_slug == 'super_admin'" class="btn btn-sm btn-danger"
                            v-on:click="hapusData(row_data.ad_id)">
                            <i class="fa fa-trash"></i> Hapus
                          </button>
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
            <!-- modal tambah  -->
            <b-modal v-model="showAkses" title="Akses" title-class="text-black font-18" body-class="p-3" hide-footer>
              <form @submit.prevent="StoreDataAkses">
                <div class="row">
                  <div class="col-12">
                    <div class="mb-3">
                      <label for="name">Akses</label>
                      <v-select v-model="master_user_selected" :options="master_user" label="name" multiple></v-select>
                    </div>
                  </div>
                </div>
                <div class="text-end">
                  <b-button variant="light" @click="showAkses = false">Close</b-button>
                  <b-button type="submit" variant="success" class="ms-1">Save</b-button>
                </div>
              </form>
            </b-modal>
          </div>
        </div>
      </div>
    </div>
  </Layout></template>
